<template>
    <div>
        <div v-if="cast && castandcrew" >
            <div class="cast-an-crew">{{$t('Cast')}}: </div> 
            <div class="cast-an-crew-value">{{cast}}</div>
        </div>

        <div v-if="crew && castandcrew">
            <div class="cast-an-crew">{{$t('Crew')}}: </div> 
            <div class="cast-an-crew-value">{{crew}}</div>
        </div>

    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props:["castCrew"],
    data() {
        return {
            cast: '',
            crew: '',
            castandcrew: null,
            appconfig: null
        }
    },
    computed: {
    ...mapGetters([
      "appConfig",
    ]),
  },
    mounted () {
         // this.appconfig = JSON.parse(localStorage.getItem("appConfig"));
        this.castandcrew = this.appConfig.featureEnabled.castandcrew;
        this.castCrew.cast && this.castCrew.cast.forEach((item) => {
            this.cast += item.cast + "," + " "; 
        })
        this.cast = this.cast.slice(0, -2)

        this.castCrew.crew && this.castCrew.crew.forEach((item) => {
            this.crew += item.crew + "("+ item.role +")," + " "; 
        })
        this.crew = this.crew.slice(0, -2)
    }
}
</script>
<style lang="scss" scoped>
    @import "@/sass/_variables.scss";
    .cast-an-crew {
        color: $clr-backnav-arrw-txt;
        display: inline;
    }
    .cast-an-crew-value {
        color: $color-white;
        display: inline;
        opacity: 0.9;
    }

</style>